import { Box, Typography } from "@mui/material";
import { ContentRenderer } from "../../Common/Components/ContentRenderer/ContentRenderer";
import theme from "../../../theme";

import konzeptData from "../../../static/konzept.json";
import konzeptImage from "../../../assets/konzept_image.png";
import konzeptImage2 from "../../../assets/konzept_image_2.png";
import { bodyStyle, headerStyle } from "../../../types/content";

export const Konzept = () => {
  const header = "Konzept des Stresstests";

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        overflowY: "scroll",
        marginTop: 2,
        padding: 5,
        height: "100%",
        width: "100%",
        flex: 1,
      }}
    >
      <Typography
        variant="h2"
        color={theme.palette.primary.main}
        component="h1"
        gutterBottom
      >
        {header}
      </Typography>

      <ContentRenderer data={konzeptData}></ContentRenderer>
      <img
        src={konzeptImage}
        alt="Konzept Image"
        style={{
          width: "100%",
          objectFit: "cover",
          borderRadius: "4px",
          marginBottom: 20,
        }}
      />
      <Typography
        variant="body1"
        color="black"
        component="h1"
        gutterBottom
        sx={bodyStyle}
      >
        Die Erfassung und Analyse der Stresse erfolgt mittels Deskriptoren
        (“beschreiben”). Die Bewertung erfolgt entlang der zwei Indikatoren
        (“aufzeigen”) Robustheit und Anpassungsfähigkeit.{" "}
      </Typography>
      <Typography {...headerStyle}>
        Konzept der Resilienz einer Stadt
      </Typography>
      <img
        src={konzeptImage2}
        alt="Konzept Image"
        style={{
          width: "100%",
          objectFit: "cover",
          borderRadius: "4px",
          marginBottom: 5,
          marginTop: 5,
        }}
      />
      <Typography
        variant="body1"
        color="black"
        component="h1"
        gutterBottom
        sx={bodyStyle}
      >
        Die Indikatoren Robustheit und Anpassungsfähigkeit stützen sich auf
        Aspekte urbaner Resilienz, nach denen die Datenquellen für die
        quantitative Erfassung der Robustheit, sowie die Fragen zur qualitativen
        Selbsteinschätzung der Anpassungsfähigkeit der Kommunen entwickelt
        werden.
      </Typography>
    </Box>
  );
};
