import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";

import { ProgressChart } from "../Charts/ProgressChart";
import { ProgressProps } from "../types";
import { descriptorMapping } from "../../../types/descriptors";

import { CityData } from "../../../types/cities";
import { useAppContext } from "../../../contexts/appContext";
import theme from "../../../theme";
import { stresses } from "../../../static/stresses";

export const Anpassungsfahigkeit = () => {
  const [filteredData, setFilteredData] = useState<any>(null);
  const [scoresExisting, setScoresExisting] = useState<boolean>(false);
  const { selectedStress, setShowQuestionnaire, cityData } = useAppContext();
  const navigate = useNavigate();

  const auswertungStress = selectedStress ?? stresses[0];
  const stressValue: string | undefined = auswertungStress?.value;

  const handleClick = () => {
    setShowQuestionnaire(true);
  };

  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();
    navigate(`/ueber-das-projekt/4`);
  };

  useEffect(() => {
    if (
      cityData !== undefined &&
      selectedStress !== undefined &&
      auswertungStress !== null
    ) {
      let scores: Array<ProgressProps> = [];
      const latestScore: CityData | undefined =
        cityData[0]?.scores?.scores_by_descriptor?.[auswertungStress.value];

      if (latestScore) {
        Object.entries(latestScore).forEach(([key, value]) => {
          if (value.hasOwnProperty("anpassungsfahigkeit")) {
            scores.push({
              descriptorName: key,
              descriptorScore: value.anpassungsfahigkeit,
            });
          }
        });
      }
      setFilteredData(scores);
    }
  }, [cityData, auswertungStress]);

  useEffect(() => {
    const stressScores = cityData[0]?.scores?.scores_by_scenario;
    if (stressScores && stressValue) {
      setScoresExisting(stressValue in stressScores);
    } else {
      setScoresExisting(false);
    }
  }, [stressValue, cityData]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        marginTop: 2,
        padding: 5,
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography variant="h5" color={theme.palette.primary.dark}>
          Anpassungsfahigkeit im Szenario {auswertungStress.label}
        </Typography>
        <Tooltip title="Klicken Sie hier, um detaillierte Informationen zu sehen">
          <InfoIcon
            sx={{ color: "#718096", marginRight: 2 }}
            onClick={handleInfoIconClick}
          />
        </Tooltip>
      </Stack>
      {scoresExisting ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {filteredData.map((item: ProgressProps, index: number) => {
            const descriptorName =
              descriptorMapping[item.descriptorName] || item.descriptorName;

            return (
              <Grid item xs={6} key={index}>
                <ProgressChart
                  descriptorScore={item.descriptorScore}
                  descriptorName={descriptorName}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: 2,
            border: `1px solid ${theme.palette.divider}`,
            marginTop: 1,
            boxShadow: 1,
            padding: 2,
          }}
        >
          <Typography variant="body1">
            Über die Umfrage zur Anpassungsfähigkeit für dieses Stressszenario
            wurden keine Beiträge eingereicht.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
