export interface Descriptor {
  id: number;
  label: string;
  anpassungs_stresses: number[];
  description: string;
}

export const descriptorMapping: { [key: string]: string } = {
  bevoelkerungsstruktur: "Bevölkerungsstruktur",
  finanzen: "Finanzen",
  notfallinfrastruktur: "Notfallinfrastruktur",
  soziale_infrastruktur: "Soziale Infrastruktur",
  sozialkapital: "Sozialkapital",
  technische_infrastruktur: "Technische Infrastruktur",
  umwelt: "Umwelt",
  wirtschaftsstruktur: "Wirtschaftsstruktur",
  wohnungsmarkt: "Wohnungsmarkt",
};
