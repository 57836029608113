import * as StressIconsFilled from "../assets/Icons/StressIconsFilled/index";
import * as StressIcons from "../assets/Icons/StressIcons/index";

export const stresses = [
  {
    id: 1,
    value: "aussenzuwanderung",
    label: "Außenzuwanderung",
    color: "#4F1E75",
    icon: StressIcons.aussenzuwanderung,
    iconFilled: StressIconsFilled.aussenzuwanderung_fill,
  },
  {
    id: 2,
    value: "schrumpfung",
    label: "Schrumpfung",
    color: "#A84EB7",
    icon: StressIcons.schrumpfung,
    iconFilled: StressIconsFilled.schrumpfung_fill,
  },
  {
    id: 3,
    value: "schwarmstadt",
    label: "Schwarmstadt",
    color: "#DA70D6",
    icon: StressIcons.schwarmstadt,
    iconFilled: StressIconsFilled.schwarmstadt_fill,
  },
  {
    id: 4,
    value: "branchenwandel_unternehmensverlust",
    label: "Branchenwandel / Unternehmensverlust",
    color: "#8B1E00",
    icon: StressIcons.branchenwandel_unternehmensverlust,
    iconFilled: StressIconsFilled.branchenwandel_unternehmensverlust_fill,
  },
  {
    id: 5,
    value: "gesellschaftliche_polarisierung",
    label: "Gesellschaftliche Polarisierung",
    color: "#AD2E01",
    icon: StressIcons.gesellschaftliche_polarisierung,
    iconFilled: StressIconsFilled.gesellschaftliche_polarisierung_fill,
  },
  {
    id: 6,
    value: "starkregen_starkniederschläge",
    label: "Starkregen / Starkniederschläge",
    color: "#CC4A0D",
    icon: StressIcons.starkregen_starkniederschläge,
    iconFilled: StressIconsFilled.starkregen_starkniederschläge_fill,
  },
  {
    id: 7,
    value: "thermische_belastung",
    label: "Thermische Belastung",
    color: "#986100",
    icon: StressIcons.thermische_belastung,
    iconFilled: StressIconsFilled.thermische_belastung_fill,
  },
  {
    id: 8,
    value: "krise_der_energieversorgung",
    label: "Krise der Energieversorgung",
    color: "#004A9C",
    icon: StressIcons.krise_der_energieversorgung,
    iconFilled: StressIconsFilled.krise_der_energieversorgung_fill,
  },
  {
    id: 9,
    value: "industrieunfall_havarie",
    label: "Industrieunfall / Havarie",
    color: "#16477F",
    icon: StressIcons.industrieunfall,
    iconFilled: StressIconsFilled.industrieunfall_fill,
  },
  {
    id: 10,
    value: "erdbeben_erdsenkungen",
    label: "Erdbeben / Erdsenkungen",
    color: "#3D4E3B",
    icon: StressIcons.erdbeben_erdsenkungen,
    iconFilled: StressIconsFilled.erdbeben_erdsenkungen_fill,
  },
  {
    id: 11,
    value: "epidemie_pandemie",
    label: "Epidemie / Pandemie",
    color: "#3E5B3C",
    icon: StressIcons.epidemie_pandemie,
    iconFilled: StressIconsFilled.epidemie_pandemie_fill,
  },
  {
    id: 12,
    value: "cyberangriffe",
    label: "Cyberangriffe",
    color: "#4A7F45",
    icon: StressIcons.cyberangriffe,
    iconFilled: StressIconsFilled.cyberangriffe_fill,
  },
];
