import { LinearProgress, Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import theme from "../../../theme";
import { ProgressProps } from "../types";

const qualitativeLabels = ["sehr niedrig", "", "", "", "sehr hoch"];

const GradientLinearProgress = styled(LinearProgress)(({ theme, value }) => {
  return {
    height: 15,
    borderRadius: 5,
    "& .MuiLinearProgress-bar": {
      borderRadius: 5,
    },
  };
});

const AxisPoints = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: theme.spacing(1),
});

export const ProgressChart = ({
  descriptorScore,
  descriptorName,
}: ProgressProps) => {
  const progress = ((descriptorScore - 1) / (5 - 1)) * 100;

  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "center",
      }}
    >
      <Typography
        variant="body1"
        color={theme.palette.primary.dark}
        gutterBottom
      >
        {descriptorName}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <Tooltip title={`Wert: ${descriptorScore}`} arrow>
            <GradientLinearProgress variant="determinate" value={progress} />
          </Tooltip>
        </Box>
      </Box>
      <AxisPoints>
        {[1, 2, 3, 4, 5].map((point, index) => (
          <Typography key={point} variant="caption" color="text.secondary">
            {qualitativeLabels[index]}
          </Typography>
        ))}
      </AxisPoints>
    </Box>
  );
};
