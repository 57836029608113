import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { stresses } from "../../static/stresses";
import { Umfrage } from "./Anpassungsfahigkeit/Umfrage";
import { DataEntry } from "./Robustheit/DataEntry";
import { postSurvey } from "../../services/data_services";
import { useAppContext } from "../../contexts/appContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IProps {
  geography: string;
  previousAnswers: any[];
  type: string;
}

export const QuestionnairePage = (props: IProps) => {
  const { geography, previousAnswers, type } = props;
  const {
    setShowQuestionnaire,
    selectedStress,
    selectedCity,
    setSelectedStress,
    anpassungsfahigkeitRobustheit,
  } = useAppContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (submitted && submissionSuccess) {
      setTimeout(() => {
        setShowQuestionnaire(false);
      }, 1000);
    }
  }, [submitted, submissionSuccess]);

  useEffect(() => {
    if (selectedStress === undefined || selectedStress === null)
      setSelectedStress(stresses[0]);
    setLoading(false);
  }, [selectedStress, setSelectedStress]);

  const handleSurveySubmission = async (surveyValues: any) => {
    if (!selectedCity) {
      console.error("Submission failed: selectedCity is null");
      setSubmissionSuccess(false);
      toast.error(
        "Es gab ein Problem mit Ihrem Beitrag. Bitte versuchen Sie es erneut."
      );
      return;
    }

    setLoading(true);
    setSubmitted(true);

    try {
      await postSurvey(geography, selectedCity, surveyValues);
      setLoading(false);
      setSubmissionSuccess(true);
      toast.success("Danke. Die Einreichung war erfolgreich!");
    } catch (error) {
      console.error("Submission failed:", error);
      setLoading(false);
      setSubmissionSuccess(false);
      toast.error(
        "Es gab ein Problem mit Ihrem Beitrag. Bitte versuchen Sie es erneut."
      );
    }
  };

  return (
    <Box
      sx={{
        height: "85vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          maxHeight: "inherit",
          overflow: "auto",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
          borderRadius: 2,
          marginTop: 2,
        }}
      >
        {loading && <CircularProgress />}
        {!submitted &&
          selectedStress &&
          (anpassungsfahigkeitRobustheit === "Anpassungsfähigkeit" ? (
            <Umfrage
              stress={selectedStress}
              showResult={handleSurveySubmission}
              previousAnswers={previousAnswers}
            />
          ) : (
            <DataEntry stress={selectedStress} />
          ))}
      </Box>
      <ToastContainer />
    </Box>
  );
};
