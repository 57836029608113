import { request } from "./request";
import { BASE_URL } from "../BASE_URL";
import { City } from "../types/cities";

interface ScenarioScore {
  robustheit: number;
  anpassungsfahigkeit: number;
}

export interface ScoresByScenario extends Record<string, any> {
  aussenzuwanderung?: ScenarioScore;
  schrumpfung?: ScenarioScore;
  schwarmstadt?: ScenarioScore;
  branchenwandel_unternehmensverlust?: ScenarioScore;
  gesellschaftliche_polarisierung?: ScenarioScore;
  starkregen_starkniederschläge?: ScenarioScore;
  thermische_belastung?: ScenarioScore;
  krise_der_energieversorgung?: ScenarioScore;
  industrieunfall_havarie?: ScenarioScore;
  erdbeben_erdsenkungen?: ScenarioScore;
  epidemie_pandemie?: ScenarioScore;
  cyberangriffe?: ScenarioScore;
}

export interface Descriptors extends Record<string, any> {
  soziale_infrastruktur?: ScenarioScore;
  sozialkapital?: ScenarioScore;
  notfallinfrastruktur?: ScenarioScore;
  technische_infrastruktur?: ScenarioScore;
  umwelt?: ScenarioScore;
  wohnungsmarkt?: ScenarioScore;
  bevoelkerungsstruktur?: ScenarioScore;
  finanzen?: ScenarioScore;
  wirtschaftsstruktur?: ScenarioScore;
}

export interface ScoresByDescriptor extends Record<string, any> {
  aussenzuwanderung?: Descriptors;
  schrumpfung?: Descriptors;
  schwarmstadt?: Descriptors;
  branchenwandel_unternehmensverlust?: Descriptors;
  gesellschaftliche_polarisierung?: Descriptors;
  starkregen_starkniederschläge?: Descriptors;
  thermische_belastung?: Descriptors;
  krise_der_energieversorgung?: Descriptors;
  industrieunfall_havarie?: Descriptors;
  erdbeben_erdsenkungen?: Descriptors;
  epidemie_pandemie?: Descriptors;
  cyberangriffe?: Descriptors;
}

export interface CityScore extends Record<string, any> {
  scores_by_scenario: ScoresByScenario;
  scores_by_descriptor: ScoresByDescriptor;
}

interface QuestionAnswer {
  question: string;
  value: number;
}

export interface GetScoresResponse {
  city_id: string;
  created_at: string;
  scores: CityScore;
  survey_results: QuestionAnswer[];
}

export const getData = async (
  selected_city: string,
  limit?: number
): Promise<GetScoresResponse[]> => {
  let url = `${BASE_URL}/data/${selected_city}`;
  if (limit !== undefined) {
    url += `?limit=${limit}`;
  }
  try {
    const response: any = await request<any[]>(url, "get");
    return response.data;
  } catch (error) {
    console.error("Error in dataServices:", error);
    return [];
  }
};
export interface SurveyResults {
  [key: string]: number;
}

export const postSurvey = async (
  geography: string,
  selectedCity: City,
  surveyResults: any[]
): Promise<GetScoresResponse | void> => {
  let url = `${BASE_URL}/data`;
  try {
    const response: any = await request<any[]>(url, "post", {
      geography: geography,
      selected_city: { key: selectedCity.id, name: selectedCity.label },
      survey_results: surveyResults,
    });
    return response.data;
  } catch (error) {
    console.error("Error in dataServices:", error);
    return;
  }
};
