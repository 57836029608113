import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { useAppContext } from "../../../contexts/appContext";
import { Cities } from "../../../static/cities";
import theme from "../../../theme";

import homepageImg from "../../../assets/background_white.png";
import { getData } from "../../../services/data_services";

export const LandingPage = () => {
  const { setSelectedCity, setCityData, selectedCity } = useAppContext();
  const navigate = useNavigate();

  const fetchCityData = async (cityId: string) => {
    if (!cityId) {
      console.log("No cityId provided, skipping fetch.");
      return;
    }
    console.log(`Fetching data for cityId: ${cityId}`);
    try {
      const result: any = await getData(cityId);
      console.log("Data fetched successfully:", result);
      setCityData(result);
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  useEffect(() => {
    setSelectedCity(null);
  }, [setSelectedCity]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedCityId = event.target.value;
    const city = Cities.find((city) => city.id === selectedCityId);

    if (city) {
      console.log(`City selected: ${city.label} (${city.id})`);
      setSelectedCity(city);
      fetchCityData(selectedCityId);
      navigate("/resilienz-profil");
    } else {
      console.error("City not found");
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${homepageImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 3,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backgroundBlendMode: "overlay",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "50%",
          padding: 5,
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 3,
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            typography: { lg: "h2", md: "h2", sm: "h3", xs: "h5" },
            textDecoration: "none",
          }}
          color={theme.palette.primary.main}
          gutterBottom
        >
          Bitte wählen Sie Ihre Stadt aus:
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "auto", mt: 3 }}
        >
          <FormControl sx={{ minWidth: "50%", backgroundColor: "#fff" }}>
            <InputLabel id="demo-simple-select-label">
              Stadt auswählen
            </InputLabel>
            <Select
              id="select-city"
              label="Stadt auswählen"
              value={selectedCity ? selectedCity.id : ""}
              onChange={handleChange}
            >
              {Cities.map((city) => (
                <MenuItem key={city.id} value={city.id} id={city.label}>
                  {city.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </Box>
  );
};
