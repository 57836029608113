import { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ContentRenderer } from "../../Common/Components/ContentRenderer/ContentRenderer";
import { ContentData } from "../../../types/content";
import theme from "../../../theme";

import { stresses } from "../../../static/stresses";
import stressData from "../../../static/stressSzenarien.json";
import { useAppContext } from "../../../contexts/appContext";

export const Stresszenarien = () => {
  const { selectedStress } = useAppContext();
  const initialStress = selectedStress?.id
    ? stressData.find((stress) => stress.id === selectedStress.id) ||
      stressData[0]
    : stressData[0];

  const [activeStress, setActiveStress] = useState<ContentData>(initialStress);

  const header = "Über 12 Stresszenarien";

  const handleClick = (id: number) => {
    const stress = stressData.find((stress) => stress.id === id);
    if (stress) {
      setActiveStress(stress);
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        overflowY: "scroll",
        marginTop: 2,
        padding: 5,
        height: "100%",
        width: "100%",
        flex: 1,
      }}
    >
      <Typography
        variant="h2"
        color={theme.palette.primary.main}
        component="h1"
        gutterBottom
      >
        {header}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        {stresses.map((stress) => (
          <Tooltip key={stress.id} title={stress.label}>
            <IconButton
              aria-label={stress.label}
              onClick={() => handleClick(stress.id)}
            >
              <img src={stress.iconFilled} alt={stress.label} />
            </IconButton>
          </Tooltip>
        ))}
      </Box>
      <ContentRenderer data={activeStress} />
    </Box>
  );
};
