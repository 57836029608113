import { Box } from "@mui/material";
import { Scatter } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import {
  gradientBackgroundPlugin,
  alwaysShowLabelsPlugin,
} from "../../../utils/resilienzMatrix";

const qualitativeLabels = ["sehr niedrig", "", "", "", "sehr hoch"];

Chart.register(
  ...registerables,
  gradientBackgroundPlugin,
  alwaysShowLabelsPlugin
);

const options = {
  animation: {
    duration: 0,
  },
  scales: {
    x: {
      min: 1,
      max: 5,
      beginAtZero: false,
      title: {
        display: true,
        text: "Robustheit",
        font: {
          size: 20,
        },
      },
      ticks: {
        stepSize: 1,
        color: "#000",
        font: {
          size: 16,
        },
        callback: function (
          tickValue: string | number,
          index: number,
          ticks: any
        ) {
          return qualitativeLabels[Number(tickValue) - 1];
        },
      },
    },
    y: {
      min: 1,
      max: 5,
      beginAtZero: false,
      title: {
        display: true,
        text: "Anpassungsfähigkeit",
        font: {
          size: 20,
        },
      },
      ticks: {
        stepSize: 1,
        color: "#000",
        font: {
          size: 16,
        },
        callback: function (tickValue: string | number) {
          return Number(tickValue) === 5
            ? qualitativeLabels[Number(tickValue) - 1]
            : "";
        },
        maxRotation: 90,
        minRotation: 90,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      bodyFont: { size: 16 },
      titleFont: { size: 20 },
      gradientBackground: true,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1,
};

type ChartProps = {
  data: any;
  loading: boolean;
};

export const StressChart = ({ data, loading }: ChartProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {!loading && <Scatter width={"auto"} data={data} options={options} />}
    </Box>
  );
};
