import { useEffect, useState } from "react";
import { Box, Tooltip, Typography, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { StressChart } from "./StressChart";
import { CityData } from "../../../types/cities";
import { CustomButton } from "../../Common/Components/Buttons/CustomButton";
import { useAppContext } from "../../../contexts/appContext";
import theme from "../../../theme";
import { processCityData } from "../../../utils/processCityData";

export const ResilienceMatrix = () => {
  const { setShowQuestionnaire, selectedStress, cityData } = useAppContext();

  const [scoresExisting, setScoresExisting] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleClick = () => setShowQuestionnaire(true);

  useEffect(() => {
    setLoading(true);

    if (cityData.length === 0) {
      setScoresExisting(false);
      setLoading(false);
    } else setScoresExisting(true);

    const formattedData: any = processCityData({
      rawData: cityData,
      filteredStress: selectedStress,
    });

    setData(formattedData);
    setLoading(false);
  }, [cityData, selectedStress, loading]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          padding: 2,
          flex: 1,
          textAlign: "center",
          display: "flex",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <StressChart data={data} loading={loading} />
        )}{" "}
      </Box>
      {!scoresExisting && !loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 2,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: 2,
            boxShadow: 3,
            zIndex: 1000,
          }}
        >
          <Typography
            variant="h5"
            color={theme.palette.primary.main}
            gutterBottom
            sx={{ marginBottom: 2 }}
          >
            Um die Grafik zu sehen, füllen Sie bitte den Fragebogen aus{" "}
          </Typography>
          <Tooltip title={"Fragebogen ausfüllen"} arrow>
            <span>
              <CustomButton onClick={() => handleClick()}>
                <AddIcon />
                Daten hinzufügen
              </CustomButton>
            </span>
          </Tooltip>{" "}
        </Box>
      )}
    </Box>
  );
};
