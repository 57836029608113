import { Box, Grid } from "@mui/material";
import theme from "../../theme";
import { StressButtons } from "../Common/Components/Buttons/StressButtons/StressButtons";
import { Robustheit } from "./Content/Robustheit";
import { Anpassungsfahigkeit } from "./Content/Anpassungsfahigkeit";
import { Footer } from "../Common/Components/Footer/Footer";

const padding = {
  xs: theme.spacing(1),
  sm: theme.spacing(2),
  md: theme.spacing(3),
  lg: theme.spacing(4),
  xl: theme.spacing(5),
};

export const Auswertung = () => {
  return (
    <Box
      sx={{
        height: "100%",
        paddingLeft: padding,
        paddingRight: padding,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          flex: 1,
          overflow: "hidden",
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: { xs: 2, sm: 0 },
          }}
        >
          <StressButtons />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          gap={2}
          sx={{
            height: "100%",
            padding: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 2,
            }}
          >
            <Box sx={{ height: "50%" }}>
              <Robustheit />
            </Box>
            <Box sx={{ height: "50%" }}>
              <Anpassungsfahigkeit />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};
