import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import theme from "../../theme";

import { StressButtons } from "../Common/Components/Buttons/StressButtons/StressButtons";
import { ResilienceMatrix } from "./ResilienceMatrix/ResilienceMatrix";
import { QuestionnairePage } from "../QuestionnairePage/QuestionnairePage";

import { useAppContext } from "../../contexts/appContext";
import { RightPane } from "./RightPane/RightPane";
import { Footer } from "../Common/Components/Footer/Footer";
import { getData } from "../../services/data_services";

const padding = {
  xs: theme.spacing(1),
  sm: theme.spacing(2),
  md: theme.spacing(3),
  lg: theme.spacing(4),
  xl: theme.spacing(5),
};

export const ResilienzProfil = () => {
  const {
    cityData,
    selectedCity,
    setSelectedStress,
    showQuestionnaire,
    setShowQuestionnaire,
    anpassungsfahigkeitRobustheit,
    setCityData
  } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!showQuestionnaire && selectedCity) {
      setSelectedStress(null);
      const fetchData = async (cityId: string) => {
        const result: any = await getData(cityId);
        setCityData(result);
        setShowQuestionnaire(false);
      };
      setSelectedStress(null);
      fetchData(selectedCity?.id);
    } else if (!selectedCity) {
      navigate("/");
    }
  }, [showQuestionnaire, setShowQuestionnaire, setSelectedStress, selectedCity, setCityData, navigate]);

  return (
    <Box
      sx={{
        height: "100%",
        paddingLeft: padding,
        paddingRight: padding,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          flex: 1,
          overflow: "hidden",
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: { xs: 2, sm: 0 },
          }}
        >
          <StressButtons />
        </Grid>
        {showQuestionnaire && selectedCity ? (
          <>
            <Grid item xs={12} sm={12} md={9} lg={9} gap={2}>
              <QuestionnairePage
                type={
                  anpassungsfahigkeitRobustheit === "Anpassungsfähigkeit"
                    ? "Anpassungsfähigkeit"
                    : "Robustheit"
                }
                geography={"geographyId"}
                previousAnswers={
                  cityData.length > 0 ? cityData[0].survey_results : []
                }
              />
            </Grid>
          </>
        ) : selectedCity ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ResilienceMatrix />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RightPane />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Footer />
    </Box>
  );
};
