import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../authConfig";
import { callMsGraph } from "../../../../graph";
import { CustomButton } from "../Buttons/CustomButton";

const LoginButton = (props: any) => {
  const { children } = props;
  const [accessToken, setAccessToken] = useState<string>("");
  const { instance, accounts } = useMsal();

  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      callMsGraph(response.accessToken).then((response) => {});
    });
    //   .catch((e) => {
    //     instance.acquireTokenPopup(request).then((response) => {
    //       callMsGraph(response.accessToken).then((response) =>
    //         console.log(response)
    //       );
    //     });
    //   });
  }

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        localStorage.setItem("access_token", response?.accessToken);
        if (response?.account.name !== undefined) {
          localStorage.setItem("user_name", response?.account.name);
        }
        localStorage.setItem("user_email", response.account.username);
        setAccessToken(response?.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenRedirect(request).then((response) => {});
      });
  }

  const handleLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .then((response) => RequestProfileData())
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <CustomButton
      size="large"
      variant="contained"
      color="primary"
      onClick={RequestAccessToken}
    >
      {children}
    </CustomButton>
  );
};

export default LoginButton;
