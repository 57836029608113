import { Box, Button } from "@mui/material";

export type ButtonProps = {
  name: string;
  onClick: () => void;
  isSelected: boolean;
};

export const UeberDasProjektButton = ({
  name,
  onClick,
  isSelected,
}: ButtonProps) => {
  const buttonStyle = {
    color: isSelected ? "white" : "#083464",
    backgroundColor: isSelected ? "#083464" : "white",
    borderRadius: "0 360px 360px 0",
    borderColor: "#083464",
    borderWidth: "2px",
    borderStyle: "solid",
    width: "100%",
    cursor: "pointer",
    fontWeight: "500",
    fontFamily: "Inter, sans-serif",
    lineHeight: "100%",
    paddingY: "1rem",
    fontSize: "1rem",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={{ width: "100%", paddingY: "0.25rem" }}>
      <Button sx={buttonStyle} onClick={onClick}>
        {name}
      </Button>
    </Box>
  );
};
