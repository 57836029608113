import { Box } from "@mui/material";
import { UeberDasProjektButton } from "./UeberDasProjektButton";
import { useNavigate } from "react-router-dom";
import theme from "../../../../../theme";

export type ButtonProps = {
  setSelectedButton: any;
};

const padding = {
  xs: theme.spacing(1),
  sm: theme.spacing(1),
  md: theme.spacing(1),
  lg: theme.spacing(1),
  xl: theme.spacing(5),
};
export const UeberDasProjektButtons = ({ setSelectedButton }: ButtonProps) => {
  const navigate = useNavigate();

  const buttons = [
    { name: "Konzept des Stresstests", id: 1 },
    { name: "Über 12 Stresszenarien", id: 2 },
    { name: "Über 9 Deskriptoren", id: 3 },
    { name: "Indikatoren 'Robustheit' und 'Anpassungsfähigkeit'", id: 4 },
  ];

  const handleButtonClick = (id: any) => {
    setSelectedButton(id);
    navigate(`/ueber-das-projekt/${id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        flexGrow: 1,
        padding: padding,
        marginBottom: 2,
      }}
    >
      {" "}
      {buttons.map((button) => (
        <UeberDasProjektButton
          key={button.id}
          name={button.name}
          onClick={() => handleButtonClick(button.id)}
          isSelected={window.location.pathname.endsWith(`/${button.id}`)}
        />
      ))}
    </Box>
  );
};
