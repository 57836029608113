import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import theme from "../../../../theme";
import { useNavigate } from "react-router-dom";

const LogoutButton = (props: any) => {
  const { children } = props;
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const handleLogout = () => {
    instance.logout().then(() => {
      navigate(`/`);
    });
  };

  return (
    <Button
      sx={{ color: theme.palette.primary.main }}
      onClick={handleLogout}
      className="ml-auto"
    >
      {children}
    </Button>
  );
};

export default LogoutButton;
