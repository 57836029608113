import { createContext, useState, useContext, ReactNode, useMemo } from "react";
import { Stress } from "../types/stresses";
import { City, CityData } from "../types/cities";
import { QuestionAnswer } from "../types/questionnaire";


interface AppContextType {
  selectedStress: Stress | null;
  setSelectedStress: (stress: Stress | null) => void;
  selectedCity: City | null;
  setSelectedCity: (city: City | null) => void;
  cityData: CityData[];
  setCityData: (cityData: CityData[]) => void;
  showQuestionnaire: boolean;
  setShowQuestionnaire: (questionnaire: boolean) => void;
  anpassungsfahigkeitRobustheit: string;
  setAnpassungsfahigkeitRobustheit: (value: string) => void;
  checkboxValues: QuestionAnswer[]
  setCheckboxValues: (value: any) => void;
  questions: any;
  setQuestions: (value: any) => void;
}

const AppContext = createContext<AppContextType>({
  selectedStress: null,
  setSelectedStress: () => { },
  selectedCity: null,
  setSelectedCity: () => { },
  cityData: [],
  setCityData: () => { },
  showQuestionnaire: false,
  setShowQuestionnaire: () => { },
  anpassungsfahigkeitRobustheit: "",
  setAnpassungsfahigkeitRobustheit: () => { },
  checkboxValues: [],
  setCheckboxValues: () => { },
  questions: [],
  setQuestions: () => { },
});

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [selectedStress, setSelectedStress] = useState<Stress | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [cityData, setCityData] = useState<CityData[]>([]);
  const [showQuestionnaire, setShowQuestionnaire] = useState<boolean>(false);
  const [anpassungsfahigkeitRobustheit, setAnpassungsfahigkeitRobustheit] =
    useState<string>("Anpassungsfähigkeit");
  const [checkboxValues, setCheckboxValues] = useState<QuestionAnswer[]>([]);
  const [questions, setQuestions] = useState<any>([]);


  const value = useMemo(
    () => ({
      selectedStress,
      setSelectedStress,
      selectedCity,
      setSelectedCity,
      cityData,
      setCityData,
      showQuestionnaire,
      setShowQuestionnaire,
      anpassungsfahigkeitRobustheit,
      setAnpassungsfahigkeitRobustheit,
      checkboxValues,
      setCheckboxValues, 
      questions, 
      setQuestions
    }),
    [selectedStress, selectedCity, cityData, showQuestionnaire, anpassungsfahigkeitRobustheit, checkboxValues, questions]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
