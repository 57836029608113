import * as DescriptorIconsFilled from "../assets/Icons/DescriptorIconsFilled/index";

const iconMap = {
  1: DescriptorIconsFilled.finanzen,
  2: DescriptorIconsFilled.bevoelkerungsstruktur,
  3: DescriptorIconsFilled.technischeInfrastruktur,
  4: DescriptorIconsFilled.sozialeInfrastruktur,
  5: DescriptorIconsFilled.wirtschaftsstruktur,
  6: DescriptorIconsFilled.wohnungsmarkt,
  7: DescriptorIconsFilled.umwelt,
  8: DescriptorIconsFilled.sozialkapital,
  9: DescriptorIconsFilled.notfallinfrastruktur,
};

export const descriptors = [
  {
    id: 1,
    label: "Finanzen",
    anpassungs_stresses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    robustheit_stresses: [],
    description:
      "Der Deskriptor Finanzen basiert auf den Kriterien der finanziellen Selbstständigkeit und Liquidität, um im Falle eines Stressszenarios die urbane Daseinsvorsorge zu gewährleisten. Die finanzielle Stärke einer Stadt ermöglicht die gezielte Umsetzung von Maßnahmen und Bewältigungsstrategien gegenüber Stressszenarien. Der Deskriptor betrifft alle Stressszenarien und fließt folglich bei jedem Stressszenario ein. ",
    iconFilled: iconMap[1],
  },
  {
    id: 2,
    label: "Bevölkerungsstruktur",
    anpassungs_stresses: [1, 2, 3, 4, 5],
    robustheit_stresses: [1, 2, 3, 5, 6, 7, 9, 10, 11],
    description:
      "Die funktionsfähige Stadt wird durch seine Bewohner:innen, dessen Zusammensetzung und Veränderung beschrieben. Zeitgleich gewährleistet eine funktionsfähige Stadt die Grundfunktionen (Wohnen, Arbeiten etc.) für das Individuum. Werden die Grundfunktionen nicht ausreichend sichergestellt, kann es zu Abwanderungstendenzen innerhalb der Bewohnerschaft kommen.",
    iconFilled: iconMap[2],
  },
  {
    id: 3,
    label: "Technische Infrastruktur",
    anpassungs_stresses: [2, 6, 8, 9, 10, 12],
    robustheit_stresses: [2, 3, 8, 12],
    description:
      "Die funktionsfähige Stadt ist gekennzeichnet durch eine verlässliche und leistungsfähige technische Infrastruktur. Insbesondere die Instandhaltung bzw. die unverzügliche Wiederherstellung von kritischen Infrastrukturen wie die Strom- und Energieversorgung müssen im Falle eines Katastrophenereignisses gewährleistet sein. Die technische Infrastruktur beinhaltet auch die digitale Infrastruktur und Informationstechnologie.",
    iconFilled: iconMap[3],
  },

  {
    id: 4,
    label: "Soziale Infrastruktur",
    anpassungs_stresses: [1, 2, 3, 5],
    robustheit_stresses: [1, 2, 3, 5, 9, 10, 11],
    description:
      "Der komplexe Deskriptor der sozialen Infrastruktur fokussiert sich auf die beiden Bereiche der Bildung und Betreuung. Die Ausgestaltung der zugehörigen Infrastrukturen und die Verfügbarkeit von Bildungs- und Betreuungsangeboten stellen zentrale Anforderungen zu der urbanen Befriedigung des Deskriptors dar. ",
    iconFilled: iconMap[4],
  },
  {
    id: 5,
    label: "Wirtschaftsstruktur",
    anpassungs_stresses: [2, 4, 8, 9, 12],
    robustheit_stresses: [2, 3, 4, 8, 12],
    description:
      "Eine funktionierende städtische Wirtschaft wird durch den erfolgreichen Umgang mit sozio-ökonomischen Schocks und Stressen definiert. Die Funktionsfähigkeit wird einerseits durch die Stabilität in Form von gleichbleibenden Einkommens- und Beschäftigungsmöglichkeiten geprägt. Andererseits zeichnet sich die resiliente, lokale Wirtschaft durch ihre Innovationskompetenz zu neuen wirtschaftlichen Lösungsansätzen und Strategien aus. ",
    iconFilled: iconMap[5],
  },
  {
    id: 6,
    label: "Wohnungsmarkt",
    anpassungs_stresses: [1, 2, 3, 5, 10],
    robustheit_stresses: [1, 2, 3, 5],
    description:
      "Die Grundfunktion des Wohnens und die Bereitstellung von Wohnraum wird in einer funktionsfähigen Stadt durch einen stadtübergreifenden Balance-Akt von Angebot und Nachfrage gekennzeichnet. Die Dynamik von Städten mit Leerstand und rückläufiger Wohnungsnachfrage steht dabei Städten mit starker Zuwanderung und daraus resultierenden Wohnraummangel gegenüber.  ",
    iconFilled: iconMap[6],
  },
  {
    id: 7,
    label: "Umwelt",
    anpassungs_stresses: [3, 6, 7],
    robustheit_stresses: [6, 7, 9, 10],
    description:
      "Der Deskriptor Umwelt wird durch urbane Grün- und Freiflächen, deren Qualität, Quantität sowie der räumlichen Verteilung geprägt. Die Strukturen fördern einerseits das menschliche Wohlergehen und die Gesundheit und andererseits stellen sie relevante Ressourcen zur Bekämpfung des Klimawandels dar. ",
    iconFilled: iconMap[7],
  },

  {
    id: 8,
    label: "Sozialkapital",
    anpassungs_stresses: [1, 2, 5, 11, 12],
    robustheit_stresses: [1, 5, 11],
    description:
      "Der facettenreiche Deskriptor Sozialkapital setzt sich einerseits durch das Vertrauen in Politik und deren Institutionen zusammen. Andererseits wird das Sozialkapital ergänzt durch private Netzwerke wie Freundschaften und Nachbarschaften, wodurch letztlich der übergreifende Zusammenhalt der Stadtgesellschaft dargestellt werden soll.  ",
    iconFilled: iconMap[8],
  },
  {
    id: 9,
    label: "Notfallinfrastruktur",
    anpassungs_stresses: [1, 6, 7, 8, 9, 10, 11, 12],
    robustheit_stresses: [],
    description:
      "Der Deskriptor Notfall- und Bewältigungskapazitäten bezeichnet alle Systeme und Dienstleistungen, die für den Notfall bereitstehen sollten. Dies sind z. B. Notfall- und Reaktionspläne, Notfall- und Rettungspersonal, Notfallausrüstung- und Materialien, Aufnahmezentren und Sicherheitsräume, Energievorräte- und Aggregate, usw. Er beinhaltet Vorsorgeaspekte die Kommunen zur Verfügung stehen.  ",
    iconFilled: iconMap[9],
  },
];
