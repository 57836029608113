import { formatDate } from "./formatDate";

export const drawRoundedRect = (
  ctx: any,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
};

export const gradientBackgroundPlugin = {
  id: "gradientBackground",
  beforeDraw: (chart: any) => {
    const { ctx, chartArea } = chart;
    if (!chartArea) {
      return;
    }
    const gradient = ctx.createLinearGradient(
      chartArea.left,
      chartArea.bottom,
      chartArea.right,
      chartArea.top
    );
    gradient.addColorStop(0, "#D7191C");
    gradient.addColorStop(0.5, "#FFFDBD");
    gradient.addColorStop(1, "#2C7BB6");

    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = gradient;
    ctx.fillRect(
      chartArea.left,
      chartArea.top,
      chartArea.width,
      chartArea.height
    );
    ctx.restore();
  },
};

export const alwaysShowLabelsPlugin = {
  id: "alwaysShowLabels",
  afterDatasetDraw: (chart: any) => {
    const { ctx, scales } = chart;
    chart.data.datasets.forEach((dataset: any) => {
      dataset.data.forEach((point: any, index: number) => {
        if (point.date) {
          const x = scales.x.getPixelForValue(point.x);
          const y = scales.y.getPixelForValue(point.y);
          const label = `${formatDate(point.date)}`;
          ctx.font = "16px Arial";
          const textWidth = ctx.measureText(label).width;
          const textHeight = 16;
          const padding = 4;
          const radius = 5;

          ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
          drawRoundedRect(
            ctx,
            x + 15 - padding,
            y - 15 - textHeight - padding,
            textWidth + 2 * padding,
            textHeight + 2 * padding,
            radius
          );

          ctx.fillStyle = "#FFF";
          ctx.fillText(label, x + 15, y - 15);
        }
      });
    });
  },
};
