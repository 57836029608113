import { request } from "./request";
import { BASE_URL } from "../BASE_URL";


interface QuestionAnswer {
  question: string;
  value: number;
  indicator: string;
}

interface Indicators extends Record<string, any> {
  [key: string]: string[];
}

interface Descriptors extends Record<string, any> {
    soziale_infrastruktur?: Indicators;
    sozialkapital?: Indicators;
    notfallinfrastruktur?: Indicators;
    technische_infrastruktur?: Indicators;
    umwelt?: Indicators;
    wohnungsmarkt?: Indicators;
    bevoelkerungsstruktur?: Indicators;
    finanzen?: Indicators;
    wirtschaftsstruktur?: Indicators;
  }
  
  export interface Scenarios extends Record<string, any> {
      aussenzuwanderung?: Descriptors;
      schrumpfung?: Descriptors;
      schwarmstadt?: Descriptors;
      branchenwandel_unternehmensverlust?: Descriptors;
      gesellschaftliche_polarisierung?: Descriptors;
      starkregen_starkniederschläge?: Descriptors;
      thermische_belastung?: Descriptors;
      krise_der_energieversorgung?: Descriptors;
      industrieunfall_havarie?: Descriptors;
      erdbeben_erdsenkungen?: Descriptors;
      epidemie_pandemie?: Descriptors;
      cyberangriffe?: Descriptors;
  }

export const getQuestions = async (flattened?: boolean): Promise<Scenarios | QuestionAnswer[]> => {
  let url = flattened ? `${BASE_URL}/questions?flattened=true` : `${BASE_URL}/questions`;
  try {
    const response: any = await request<any[]>(url, "get");
    return response.data;
  } catch (error) {
    console.error("Error getting questions:", error);
    return [];
  }
};
