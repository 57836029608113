import axios, { AxiosResponse } from "axios";

type Method = "get" | "post" | "put" | "delete";

export const request = async <T>(
  url: string,
  method: Method,
  data?: any
): Promise<AxiosResponse<T>> => {
  try {
    method = method.toLowerCase() as Method;

    const response: AxiosResponse =
      method === "get" || method === "delete"
        ? await axios[method](url)
        : await axios[method](url, data);

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);
      throw error;
    } else {
      console.error("Unexpected error:", error);
      throw error;
    }
  }
};
