import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { StressButton } from "./StressButton";
import { stresses } from "../../../../../static/stresses";
import { Stress } from "../../../../../types/stresses";
import { useAppContext } from "../../../../../contexts/appContext";
import theme from "../../../../../theme";

const padding = {
  xs: theme.spacing(1),
  sm: theme.spacing(1),
  md: theme.spacing(1),
  lg: theme.spacing(1),
  xl: theme.spacing(5),
};

export const StressButtons = () => {
  const { setSelectedStress, selectedStress, setShowQuestionnaire } =
    useAppContext();
  const [activeButton, setActiveButton] = useState<number | null>(null);

  const handleClick = (stress: Stress) => {
    if (activeButton === stress.id) {
      setShowQuestionnaire(false);
      setSelectedStress(null);
      setActiveButton(null);
    } else {
      setSelectedStress(stress);
      setActiveButton(stress.id);
    }
  };

  useEffect(() => {
    if (selectedStress === null) {
      setActiveButton(null);
    } else setActiveButton(selectedStress.id);
  }, [selectedStress]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexGrow: 1,
        padding: padding,
        marginBottom: 2,
      }}
    >
      <Grid container spacing={0} justifyContent="center">
        {stresses.map((stress) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={stress.id}>
            <StressButton
              stress={stress}
              isActive={activeButton === stress.id}
              onClick={() => handleClick(stress)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
