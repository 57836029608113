import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { ContentData, bodyStyle, headerStyle } from "../../../../types/content";

export const ContentRenderer: React.FC<{ data: ContentData }> = ({ data }) => {
  return (
    <Box>
      {data.header && <Typography {...headerStyle}>{data.header}</Typography>}

      {data.paragraphs &&
        data.paragraphs.map((paragraph: any, idx: number) => (
          <Typography
            key={idx}
            {...bodyStyle}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        ))}

      {data.list && (
        <List>
          {data.list.map((item: any, index: number) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      )}

      {data.image && (
        <Box sx={{ textAlign: "center", marginTop: 2 }}>
          <img
            src={data.image}
            alt={data.header}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      )}
    </Box>
  );
};
