import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import type { Configuration } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import "@fontsource/pt-serif/700.css";
import "@fontsource/pt-serif/400.css"; // Regular weight

import App from "./App";

const container = document.getElementById("root");
const root = container && createRoot(container);
// const appElement = document.getElementById("app");
const msalInstance = new PublicClientApplication(msalConfig as Configuration);

root?.render(
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
);
