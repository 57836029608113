import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    issue: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = () => {
    // Create the mailto link with dynamic content
    const mailtoLink = `mailto:hannah.kissick@arup.com?subject=Issue%20Report%20from%20${encodeURIComponent(
      formData.name
    )}&body=Name: ${encodeURIComponent(
      formData.name
    )}%0AEmail: ${encodeURIComponent(
      formData.email
    )}%0AIssue:%0A${encodeURIComponent(formData.issue)}`;

    // Open the mailto link
    window.location.href = mailtoLink;
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        padding: (theme) => theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "auto",
        maxHeight: "5vh",
        gap: "10px",
      }}
    >
      <>
        <Button
          variant="contained"
          color="secondary"
          aria-label="report"
          onClick={handleClickOpen}
        >
          <ReportProblemIcon />
          <Typography>&nbsp;</Typography>
          <Typography variant="body1" sx={{ textTransform: "none" }}>
            Support
          </Typography>
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Report an Issue</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Your Name"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              id="email"
              label="Your Email"
              type="email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              id="issue"
              label="Describe the issue"
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={formData.issue}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Typography variant="body2" color="textSecondary">
        &copy; {currentYear} Arup. All rights reserved.
      </Typography>
    </Box>
  );
};
