import { stresses } from "../static/stresses";
import { Dataset, ProcessDataProps, StressesMap } from "../types/charts";
import { Stress } from "../types/stresses";

export const stressesMap: StressesMap = stresses.reduce(
  (acc: StressesMap, stress: Stress) => {
    acc[stress.value] = stress;
    return acc;
  },
  {}
);

export const processCityData = ({
  rawData,
  filteredStress,
}: ProcessDataProps) => {
  let datasets: Dataset[] = [];

  Object.keys(stressesMap).forEach((stress: string) => {
    const sortedByDate = rawData.filter(
      (entry) => entry.scores.scores_by_scenario[stress]
    );

    let dataPoints = [];

    if (filteredStress && filteredStress.value === stress) {
      // when stress clicked plot all the data points only for this value
      dataPoints = sortedByDate.map((entry) => ({
        x: entry.scores.scores_by_scenario[stress].robustheit,
        y: entry.scores.scores_by_scenario[stress].anpassungsfahigkeit,
        date: entry.created_at,
      }));
    } else {
      const mostRecentData = sortedByDate[0];
      if (mostRecentData) {
        dataPoints.push({
          x: mostRecentData.scores.scores_by_scenario[stress].robustheit,
          y: mostRecentData.scores.scores_by_scenario[stress]
            .anpassungsfahigkeit,
        });
      }
    }

    const isFilteredStress = filteredStress && filteredStress.value === stress;
    const label = stressesMap[stress].label;

    var icon = new Image();
    icon.src =
      !filteredStress || isFilteredStress ? stressesMap[stress].iconFilled : "";

    const color =
      !filteredStress || isFilteredStress ? stressesMap[stress].color : "grey";

    const pointRadius = !filteredStress || isFilteredStress ? 25 : 5;

    if (dataPoints.length > 0) {
      datasets.push({
        label: label,
        data: dataPoints,
        backgroundColor: color,
        borderColor: color,
        showLine: true,
        pointRadius: pointRadius,
        pointStyle: icon,
        borderWidth: 2,
        fill: false,
      });
    }
  });

  return { datasets };
};
