import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Divider,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from '@mui/material/Tooltip';
import { getQuestions } from "../../../services/survey_services";
import { useAppContext } from "../../../contexts/appContext";

import { Stress } from "../../../types/stresses";
import { QuestionAnswer } from "../../../types/questionnaire";
import { CustomButton } from "../../Common/Components/Buttons/CustomButton";
import theme from "../../../theme";

interface ResultsProps {
  stress: Stress;
  showResult: (values: any) => void;
  previousAnswers: any[];
}

const scoreMapping: Record<string, any> = {
  0: "Keine Angabe",
  1: "Nicht auf der Agenda",
  2: "In Planung",
  3: "Vorhanden",
  4: "In der Praxis bewährt",
  5: "Bewährt & fortgeschrieben",
};

const scoreDefinitions: any = {
  "Keine Angabe": "die Beantwortung ist derzeit nicht möglich.",
  "Nicht auf der Agenda": "Das Thema ist bekannt, wird derzeit aber nicht aktiv verfolgt.",
  "In Planung": "Das Thema ist bekannt, Verantwortlichkeiten sind skizziert, Fördermöglichkeiten sind identifiziert, Zeithorizont 6 Monate",
  "Vorhanden": "Das Thema ist in der Kommune umgesetzt, wenn auch nur lokal oder zeitlich begrenzt,  Zeithorizont 1-3 Jahre.",
  "In der Praxis bewährt": "Das Thema ist in der Kommune verstetigt und flächendeckend implementiert Zeithorizont 3-5 Jahre.",
  "Bewährt & fortgeschrieben": "Das Thema breit in der Kommune verankert und wurde bereits angepasst oder erweitert, Zeithorizont mehr als 5 Jahre.",
}

export const Umfrage = ({
  stress,
  showResult,
  previousAnswers,
}: ResultsProps) => {
  // const [questions, setQuestions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorQuestions, setErrorQuestions] = useState<string[]>([]);
  const { checkboxValues, setCheckboxValues, questions, setQuestions, cityData } = useAppContext();

  const { setShowQuestionnaire } = useAppContext();

  useEffect(() => {
    setCheckboxValues(previousAnswers)
    const fetchData = async () => {
      const result: any = await getQuestions();
      setQuestions(result);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    createInitialState().then((output: any) => {
      setCheckboxValues(updateFromPreviousAnswers(output, previousAnswers));
    });
    setLoading(false);
  }, []);

  const createInitialState = async () => {
    const result: any = await getQuestions(true);
    return result;
  };

  const updateFromPreviousAnswers = (questions: any[], previous: any[]) => {
    let out: any[] = [];
    questions.forEach((item) => {
      let prevMatch = previous.filter(
        (prev) => prev.question === item.question
      );
      if (prevMatch.length > 0) {
        item.value = prevMatch[0].value;
      }
      out.push(item);
    });
    return out;
  };

  /*const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment !== null) {
      setAnpassungsfahigkeitRobustheit(newAlignment);
    }
  };*/

  const handleCheckboxChange = (question: string, event: any) => {
    const selectedValue = event.target.value;
    setCheckboxValues((prevValues: any) =>
      prevValues.map((item: any) =>
        item.question === question
          ? { ...item, value: Number(selectedValue) }
          : item
      )
    );
  };

  const handleSubmit = () => {
    const unansweredQuestions = checkboxValues.filter(
      (item) => item.value === undefined
    );

    if (unansweredQuestions.length > 0) {
      setErrorQuestions(unansweredQuestions.map((item) => item.question));
      return;
    }

    setErrorQuestions([]);
    showResult(checkboxValues);
  };

  const handleCancel = () => {
    setShowQuestionnaire(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowY: "hidden",
        padding: 4,
      }}
    >
      {/*
      <ToggleButtonGroup
        color="primary"
        value={anpassungsfahigkeitRobustheit}
        exclusive
        onChange={handleToggleChange}
        sx={{ justifyContent: "center", maxHeight: "30px", marginBottom: 2 }}
      >
        <ToggleButton value="Anpassungsfähigkeit">
          Anpassungsfähigkeit
        </ToggleButton>
        <ToggleButton value="Robustheit">Robustheit</ToggleButton>
      </ToggleButtonGroup> */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1,
          borderBottom: "1px solid #ccc",
          textAlign: "center",
          marginBottom: 3,
        }}
      >
        {Object.entries(scoreMapping).map(([value, label]) => (
          <Box
            key={value}
            sx={{
              padding: "10px 0",
              marginBottom: 1,
            }}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography variant="h6" color="#718096">
              {label}
            </Typography>
            <Tooltip title={scoreDefinitions[label]}>
              <InfoIcon fontSize="small" color="secondary" sx={{marginLeft: "0.5rem", mt: "-1rem"}}/>
            </Tooltip>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          overflowY: "auto",
          flex: 1,
          paddingY: 1,
          paddingX: 4,
        }}
      >
        {questions && questions[stress.value] && !loading ? (
          Object.keys(questions[stress.value]).map((descriptorKey, index) => (
            <Box key={index} sx={{ width: "100%" }}>
              {Object.keys(questions[stress.value][descriptorKey]).map(
                (indicatorKey) =>
                  questions[stress.value][descriptorKey][indicatorKey].map(
                    (question: string, questionIndex: number) => (
                      <Box
                        key={questionIndex}
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(6, 1fr)",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            gridColumn: "1 / span 6",
                            marginBottom: 2,
                          }}
                        >
                          {question}
                        </Typography>
                        <RadioGroup
                          row
                          aria-label="survey"
                          name="survey"
                          onChange={(event) =>
                            handleCheckboxChange(question, event)
                          }
                          value={
                            checkboxValues.find(
                              (obj) => obj.question === question
                            )?.value ?? ""
                          }
                          sx={{
                            gridColumn: "1 / span 6",
                            display: "grid",
                            gridTemplateColumns: "repeat(6, 1fr)",
                            marginBottom: 5,
                          }}
                        >
                          {Object.entries(scoreMapping).map(
                            ([value, label]) => (
                              <FormControlLabel
                                value={value}
                                control={<Radio />}
                                label=""
                                sx={{
                                  justifyContent: "center",
                                  display: "flex",
                                  width: "100%",
                                  marginBottom: 2,
                                }}
                              />
                            )
                          )}
                        </RadioGroup>
                      </Box>
                    )
                  )
              )}
            </Box>
          ))
        ) : (
          <Typography
            variant="h5"
            sx={{ textAlign: "center", padding: "20px" }}
          >
            Es gibt noch keine Umfrage für dieses Szenario.
          </Typography>
        )}
        <Stack
          direction={"row"}
          spacing={2}
          sx={{ justifyContent: "center", marginTop: 2 }}
        >
          <CustomButton
            sx={{ width: "auto" }}
            onClick={handleSubmit}
            variant="contained"
          >
            Resilienz bewerten
          </CustomButton>
          <CustomButton
            onClick={handleCancel}
            variant="contained"
            sx={{ backgroundColor: "darkred", width: "auto" }}
          >
            Abbrechen
          </CustomButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default Umfrage;
