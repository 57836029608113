import theme from "../theme";

export const headerStyle = {
  variant: "h5" as const,
  color: theme.palette.secondary.light,
  component: "h2" as const,
  gutterBottom: true,
  marginTop: 2,
};

export const bodyStyle = {
  variant: "body1" as const,
  component: "p" as const,
  mt: 1,
  mb: 2,
};

export const navStyle = {
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
  color: theme.palette.secondary.light,
};

export type ContentData = {
  id: number;
  value?: string;
  header?: string;
  paragraphs?: string[];
  list?: string[];
  image?: string;
  descriptors?: number[];
  indictors?: number[];
  endParagraph?: string;
};
