import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Stress } from "../../../types/stresses";
import { useAppContext } from "../../../contexts/appContext";
import theme from "../../../theme";

interface ResultsProps {
  stress: Stress;
}

export const DataEntry = ({ stress }: ResultsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { setShowQuestionnaire } = useAppContext();

  const handleSubmit = () => {
    setShowQuestionnaire(false);
  };

  const handleCancel = () => {
    setShowQuestionnaire(false);
  };

  useEffect(() => {
    // potentially set loading based on an async function or remove if not needed
  }, [stress]);

  return (
    <Box
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxHeight: "90vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{
            "&:hover": { backgroundColor: theme.palette.primary.main },
            margin: 1,
            borderRadius: "20px",
          }}
        >
          Robustheit bewerten
        </Button>
        <Button
          onClick={handleCancel}
          variant="contained"
          sx={{
            backgroundColor: "darkred",
            "&:hover": { backgroundColor: "darkred" },
            margin: 1,
            borderRadius: "20px",
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
