import { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ContentRenderer } from "../../Common/Components/ContentRenderer/ContentRenderer";
import { ContentData } from "../../../types/content";
import theme from "../../../theme";

import { descriptors } from "../../../static/descriptors";
import descriptorData from "../../../static/descriptoren.json";

export const Deskriptoren = () => {
  const [activeDescriptor, setActiveDescriptor] = useState<ContentData>(
    descriptorData[0]
  );

  const header = "Über 9 Deskriptoren";

  const handleClick = (id: number) => {
    const descriptor = descriptorData.find(
      (descriptor) => descriptor.id === id
    );
    if (descriptor) {
      setActiveDescriptor(descriptor);
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        overflowY: "scroll",
        marginTop: 2,
        padding: 5,
        height: "100%",
        width: "100%",
        flex: 1,
      }}
    >
      <Typography
        variant="h2"
        color={theme.palette.primary.main}
        component="h1"
        gutterBottom
      >
        {header}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        {descriptors.map((descriptor) => (
          <Tooltip key={descriptor.id} title={descriptor.label}>
            <IconButton
              aria-label={descriptor.label}
              onClick={() => handleClick(descriptor.id)}
            >
              <img src={descriptor.iconFilled} alt={descriptor.label} />
            </IconButton>
          </Tooltip>
        ))}
      </Box>
      <ContentRenderer data={activeDescriptor}></ContentRenderer>
    </Box>
  );
};
