import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import theme from "./theme";
import { ResilienzProfil } from "./components/Homepage/ResilienzProfil";
import { AppProvider } from "./contexts/appContext";
import { LandingPage } from "./components/Common/LandingPage/LandingPage";
import LoginPage from "./components/Common/LoginPage/LoginPage";
import { Header } from "./components/Common/Components/Header/Header";
import { Footer } from "./components/Common/Components/Footer/Footer";
import { Auswertung } from "./components/AuswertungPage/Auswertung";
import { UeberDasProjekt } from "./components/UeberDasProjektPage/UeberDasProjekt";
import { NavBar } from "./components/Common/Components/NavBar/NavBar";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <BrowserRouter>
          <CssBaseline />
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/" element={<LoginPage />} />
            </Routes>
            <Footer />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Header />
                <ConditionalNavBar />
                <Routes>
                  <Route
                    path="/resilienz-profil"
                    element={<ResilienzProfil />}
                  />
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/auswertung" element={<Auswertung />} />
                  <Route
                    path="/ueber-das-projekt/:contentId"
                    element={<UeberDasProjekt />}
                  />
                </Routes>
              </Box>
            </Box>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </AppProvider>
    </ThemeProvider>
  );
}

function ConditionalNavBar() {
  const location = useLocation();
  const showNavBar = [
    "/resilienz-profil",
    "/auswertung",
    "/ueber-das-projekt/1",
    "/ueber-das-projekt/2",
    "/ueber-das-projekt/3",
    "/ueber-das-projekt/4",
    "/ueber-das-projekt/5",
  ].includes(location.pathname);

  return showNavBar ? <NavBar /> : null;
}

export default App;
