import { autocompleteClasses, Box, Stack, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const NavBar = () => {
  const theme = useTheme();
  const location = useLocation();

  const links = [
    {
      href: "/ueber-das-projekt/1",
      label: "Über das Projekt",
    },
    { href: "/resilienz-profil", label: "Resilienz Profil" },
    { href: "/auswertung", label: "Auswertung" },
  ];

  return (
    <Box
      sx={{
        height: "auto",
        maxHeight: "5vh",
        width: "100%",
        backgroundColor: "#F0F0F0",
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        borderBottom: "1px solid white",
      }}
    >
      <Stack direction="row" spacing={6} sx={{ alignItems: "center" }}>
        {links.map((link) => (
          <Link
            style={{
              cursor: "pointer",
              fontWeight:
                location.pathname === link.href ||
                (link.href.startsWith("/ueber-das-projekt") &&
                  location.pathname.startsWith("/ueber-das-projekt"))
                  ? "bold"
                  : "normal",
              color: theme.palette.primary.dark,
              textDecoration: "none",
            }}
            to={link.href}
            key={link.href}
            color={theme.palette.primary.dark}
          >
            {link.label}
          </Link>
        ))}
      </Stack>
    </Box>
  );
};
