import { useNavigate } from "react-router-dom";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import theme from "../../../theme";
import { useAppContext } from "../../../contexts/appContext";
import { CustomButton } from "../../Common/Components/Buttons/CustomButton";

export const RightPane = () => {
  const {
    setShowQuestionnaire,
    setAnpassungsfahigkeitRobustheit,
    selectedStress,
  } = useAppContext();

  const navigate = useNavigate();

  const handleClick = (type: string) => {
    setShowQuestionnaire(true);
    setAnpassungsfahigkeitRobustheit(type);
  };

  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();

    navigate(`/ueber-das-projekt/4`);
  };

  const renderButton = (type: string) => (
    <Tooltip
      title={
        selectedStress === null ? "Bitte ein Stressszenario auswählen" : ""
      }
      arrow
    >
      <span>
        <CustomButton
          onClick={() => handleClick(type)}
          disabled={selectedStress === null}
        >
          <AddIcon />
          Daten hinzufügen
        </CustomButton>
      </span>
    </Tooltip>
  );

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        textAlign: "match-parent",
        borderRadius: "16px",
        boxShadow: 1,
        marginBottom: 2,
        p: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 4,
        },
      }}
    >
      <Stack
        direction="column"
        spacing={{
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
          xl: 6,
        }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h5" color={theme.palette.primary.dark}>
              <b>Robustheit</b>
            </Typography>
            <Tooltip title="Klicken Sie hier, um detaillierte Informationen zu sehen">
              <InfoIcon
                sx={{ color: "#718096", marginRight: 2 }}
                onClick={handleInfoIconClick}
              />
            </Tooltip>
          </Stack>
          <Typography
            sx={{
              typography: {
                lg: "body1",
                md: "body1",
                sm: "subtitle1",
                xs: "subtitle1",
              },
            }}
            color={theme.palette.primary.dark}
          >
            Die Robustheit einer Kommune gegenüber eines bestimmten Stresses
            bezieht sich auf das Ausmaß, in dem die Kommune betroffen und
            verwundbar ist.
          </Typography>
          {/* 
          {renderButton("Robustheit")} */}
        </Stack>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h5" color={theme.palette.primary.dark}>
              <b>Anpassungsfähigkeit</b>
            </Typography>
            <Tooltip title="Klicken Sie hier, um detaillierte Informationen zu sehen">
              <InfoIcon
                sx={{ color: "#718096", marginRight: 2 }}
                onClick={handleInfoIconClick}
              />
            </Tooltip>
          </Stack>
          <Typography
            sx={{
              typography: {
                lg: "body1",
                md: "body1",
                sm: "subtitle1",
                xs: "subtitle1",
              },
            }}
            color={theme.palette.primary.dark}
          >
            Die Anpassungsfähigkeit einer Kommune bezieht sich auf die Fähigkeit
            und die verfügbaren Ressourcen, die Schäden eines bestimmten
            Stresses zu bewältigen.
          </Typography>
          {renderButton("Anpassungsfähigkeit")}
        </Stack>
      </Stack>
    </Box>
  );
};
