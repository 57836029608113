import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ['"Inria Sans"', "sans-serif"].join(","),
    htmlFontSize: 16,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: "3.625rem",
      color: "primary.main",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 400,
      lineHeight: "2.5rem",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 400,
      lineHeight: "1.75rem",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: "2.5rem",
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.75rem",
    },
    h6: {
      fontSize: ".75rem",
      fontWeight: 600,
      lineHeight: ".875rem",
    },
    body1: { fontSize: "1rem", fontWeight: 400, lineHeight: "24px" },
    body2: { fontSize: ".75rem", fontWeight: 400, lineHeight: ".875rem" },
    subtitle1: { fontSize: "1rem", fontWeight: 400, lineHeight: "1.5rem" },
    subtitle2: { fontSize: ".6rem", fontWeight: 700, lineHeight: "0.75rem" },
  },
  palette: {
    background: {
      default: "#F0F0F0", // light grey background color#F0F0F0
    },
    primary: {
      main: "#083464", // blue
      dark: "#134683", // dark blue
      light: "#f1f1f1", // light grey
    },
    secondary: {
      main: "#0073A8", // lighter blue (button),
      light: "#8A2BE2", // purple subheader
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ::-webkit-scrollbar {
          width: 8px;
        }
        ::-webkit-scrollbar-track {
          background-color: #f0f0f0;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #888;
          border-radius: 10px;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "subtitle1",
          subtitle2: "subtitle2",
          body1: "span",
          body2: "span",
        },
      },
    },
  },
});

export default theme;
