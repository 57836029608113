import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import theme from "../../theme";
import { Box, Grid } from "@mui/material";
import { UeberDasProjektButtons } from "../Common/Components/Buttons/UeberDasProjektButtons/UeberDasProjektButtons";
import { Stresszenarien } from "./Content/Stresszenarien";
import { Deskriptoren } from "./Content/Deskriptoren";
import { RobustheitAnpassungsfahigkeit } from "./Content/RobustheitAnpassungsfahigkeit";
import { Konzept } from "./Content/Konzept";

const padding = {
  xs: theme.spacing(1),
  sm: theme.spacing(2),
  md: theme.spacing(3),
  lg: theme.spacing(4),
  xl: theme.spacing(5),
};

export const UeberDasProjekt = () => {
  const { contentId } = useParams<{ contentId: string }>();
  const [selectedButton, setSelectedButton] = useState<number>(
    contentId ? parseInt(contentId, 10) : 1
  );

  useEffect(() => {
    if (contentId) {
      setSelectedButton(parseInt(contentId, 10));
    }
  }, [contentId]);

  const renderContent = () => {
    switch (selectedButton) {
      case 1:
        return <Konzept />;
      case 2:
        return <Stresszenarien />;
      case 3:
        return <Deskriptoren />;
      case 4:
        return <RobustheitAnpassungsfahigkeit />;
      default:
        return <Konzept />;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingLeft: padding,
        paddingRight: padding,
      }}
    >
      {" "}
      <Grid
        container
        spacing={2}
        sx={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 2,
            mb: { xs: 2, sm: 0 },
          }}
        >
          <UeberDasProjektButtons setSelectedButton={setSelectedButton} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "80vh",
              marginBottom: 5,
            }}
          >
            {renderContent()}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
