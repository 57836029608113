import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import robustheitAnpassungsfahigkeitData from "../../../static/robustheit_anpassungsfahigkeit.json";
import { ContentRenderer } from "../../Common/Components/ContentRenderer/ContentRenderer";

export const RobustheitAnpassungsfahigkeit = () => {
  const header = "2 Indikatoren 'Robustheit' und 'Anpassungsfähigkeit'";

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        overflowY: "scroll",
        marginTop: 2,
        padding: 5,
        height: "100%",
        width: "100%",
        flex: 1,
      }}
    >
      <Typography
        variant="h2"
        color={theme.palette.primary.main}
        component="h1"
        gutterBottom
      >
        {header}
      </Typography>

      {robustheitAnpassungsfahigkeitData.map(
        (robustheitAnpassungsfahigkeitData) => (
          <Box
            key={robustheitAnpassungsfahigkeitData.id}
            id={robustheitAnpassungsfahigkeitData.id.toString()}
          >
            <ContentRenderer data={robustheitAnpassungsfahigkeitData} />
          </Box>
        )
      )}
    </Box>
  );
};
