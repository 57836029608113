import { Box, Button, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router";
import { Stress } from "../../../../../types/stresses";
import { useAppContext } from "../../../../../contexts/appContext";
import theme from "../../../../../theme";

type ButtonProps = {
  stress: Stress;
  isActive: boolean;
  onClick: () => void;
};

const padding = {
  xs: theme.spacing(1),
  sm: theme.spacing(1),
  md: theme.spacing(1),
  lg: theme.spacing(1),
  xl: theme.spacing(5),
};

export const StressButton = ({ stress, isActive, onClick }: ButtonProps) => {
  const { setSelectedStress } = useAppContext();
  const navigate = useNavigate();

  const buttonColor = stress.color;
  const color = isActive ? buttonColor : "white";
  const label = stress.label;
  const icon = stress.icon;

  const buttonStyle = {
    color: isActive ? "white" : buttonColor,
    backgroundColor: color,
    borderRadius: "0 360px 360px 0",
    width: "100%",
    border: `0.1rem solid ${buttonColor}`,
    cursor: "pointer",
    fontWeight: "500",
    fontFamily: "Inter, sans-serif",
    lineHeight: "100%",
    paddingY: {
      xs: "0",
      sm: "0.5rem",
      md: "0.5rem",
      lg: "0.5rem",
      xl: "0.75rem",
    },
    fontSize: {
      xs: "0.5rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1rem",
    },
    textTransform: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: buttonColor,
      color: "white",
    },
  };

  const handleInfoIconClick = (event: any) => {
    setSelectedStress(stress);
    event.stopPropagation();
    navigate("/ueber-das-projekt/2");
  };

  return (
    <Box sx={{ width: "100%", paddingY: "0.25rem" }}>
      <Button
        startIcon={
          <img src={icon} style={{ marginLeft: 20, height: "20px" }} alt="" />
        }
        endIcon={
          <Tooltip title="Klicken Sie hier für weitere Informationen zum Stressszenario">
            <InfoIcon
              sx={{ color: buttonColor, marginRight: 2 }}
              onClick={handleInfoIconClick}
            />
          </Tooltip>
        }
        sx={buttonStyle}
        onClick={onClick}
      >
        {label}
      </Button>
    </Box>
  );
};
