import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const CustomButton = styled(Button)(({ theme, disabled }) => ({
  paddingX: 1,
  backgroundColor: disabled ? "lightgrey" : theme.palette.secondary.main,
  color: disabled ? "darkgrey" : "white",
  "&:hover": {
    backgroundColor: disabled ? "lightgrey" : "darkgrey",
    color: disabled ? "darkgrey" : "black",
  },
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  width: "100%",
}));
