import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsAuthenticated } from "@azure/msal-react";

import LogoutButton from "./LogoutButton";

import logo from "../../../../assets/bssr_logo_no_background.png";
import { useAppContext } from "../../../../contexts/appContext";

export const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isAuthenticated = useIsAuthenticated();
  const { selectedCity } = useAppContext();

  return (
    <AppBar
      elevation={0}
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "60px",
      }}
      position="static"
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          paddingLeft: "2%",
        }}
      >
        <Typography
          color={theme.palette.primary.dark}
          sx={{
            typography: { lg: "h4", md: "h4", sm: "h5", xs: "h5" },
            textDecoration: "none",
          }}
          component={Link}
          to="/"
        >
          <b>Stresstest</b> für Städte
          {selectedCity ? ` - ${selectedCity.label}` : ""}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: isMobile ? "150px" : "273px",
              marginRight: "16px",
            }}
          />
          {!isAuthenticated ? (
            <></>
          ) : (
            <LogoutButton>
              <Tooltip title="Logout">
                <LogoutIcon sx={{ cursor: "pointer" }} />
              </Tooltip>
            </LogoutButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
