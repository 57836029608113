import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, Link } from "@mui/material";
import theme from "../../../theme";
import logo from "../../../assets/bbsr_logo.png";
import logoArup from "../../../assets/logoArup.png";
import homepageImg from "../../../assets/background_white.png";
import LoginButton from "../Components/Header/LoginButton";

const LoginPage = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${homepageImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 3,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backgroundBlendMode: "overlay",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 600,
          padding: 5,
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 3,
          textAlign: "center",
        }}
      >
        <Stack
          direction="row"
          spacing={5}
          justifyContent="center"
          alignItems="center"
          sx={{ marginBottom: 4 }}
        >
          <Box
            component="img"
            src={logoArup}
            alt="Arup Logo"
            sx={{ width: 120 }}
          />
          <Box component="img" src={logo} alt="Logo" sx={{ width: 120 }} />
        </Stack>

        <Typography
          variant="h2"
          color={theme.palette.primary.main}
          gutterBottom
          sx={{ marginBottom: 3 }}
        >
          Willkommen bei Stresstest für Städte
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 3 }}>
          Bitte melden Sie sich mit Ihrer Microsoft Authenticator-E-Mail an.
        </Typography>

        <Stack spacing={4} direction="column">
          <LoginButton children="Anmelden" />
          <Typography variant="body1" gutterBottom>
            Wenn Sie bei diesem Schritt Hilfe benötigen oder noch keine E-Mail
            bei Microsoft Authenticator registriert haben, wenden Sie sich bitte
            an das Projektteam.
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default LoginPage;
