import { City } from "../types/cities";

export const Cities: City[] = [
  { label: "Aachen", id: "05334002" },
  { label: "Augsburg", id: "09761000" },
  { label: "Guben", id: "12071160" },
  { label: "Hagen", id: "05914000" },
  { label: "Hannover", id: "03241001" },
  { label: "Herne", id: "05916000" },
  { label: "Jena", id: "16053000" },
  { label: "Leverkusen", id: "05316000" },
  { label: "Wuppertal", id: "05124000" },
  { label: "Rellingen", id: "01056" },
  { label: "Schwerte", id: "05978" },
  { label: "DemoStadt", id: "6534001" },
];
